import logoBol from '/components/home/assets/logo-bol.svg'
import logoRituals from '/components/home/assets/logo-rituals.svg'
import logoLaTrappe from '/components/home/assets/logo-la-trappe.svg'
import logoNijntjeMuseum from '/components/home/assets/logo-nijntje-museum.svg'
import logoAlbron from '/components/home/assets/logo-albron.svg'
import logoRabobank from '/components/home/assets/logo-rabobank.svg'
import logoAlliander from '/components/home/assets/logo-alliander.svg'
import logoEiffel from '/components/home/assets/logo-eiffel.svg'
import logoKLM from '/components/home/assets/logo-klm.svg'
import logoSlachtofferhulp from '/components/home/assets/logo-slachtofferhulp.svg'

export const clients = [
  { name: 'Bol', logo: logoBol },
  { name: 'Rituals', logo: logoRituals },
  { name: 'La Trappe', logo: logoLaTrappe },
  { name: 'Nijntje Museum', logo: logoNijntjeMuseum },
  { name: 'Albron', logo: logoAlbron },
  { name: 'Rabobank', logo: logoRabobank },
  { name: 'Alliander', logo: logoAlliander },
  { name: 'Eiffel', logo: logoEiffel },
  { name: 'KLM', logo: logoKLM },
  { name: 'Slachtofferhulp', logo: logoSlachtofferhulp },
]
