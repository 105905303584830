import { animated, useSpring } from '@react-spring/web'
import { useScrollProgression, triggers } from '@kaliber/scroll-progression'

import { awards } from '/components/expertise/assets/awards'
import { clients } from '/components/expertise/assets/clients'
import { CenteredContainer } from '/components/pageOnly/buildingBlocks/CenteredContainer'
import { HeadingLg } from '/components/buildingBlocks/Heading'

import styles from './CustomersAndAwards.css'

export function CustomersAndAwards() {
  const [awardsIsActive, setAwardsIsActive] = React.useState(false)

  const [{ scaleY }, api] = useSpring(() => ({
    scaleY: 0,
    config: { tension: 400, friction: 35 }
  }))

  const ref = useScrollProgression({
    start: { element: triggers.bottom(), scrollParent: triggers.bottom() },
    end: { element: triggers.top(), scrollParent: triggers.top() },
    onChange(progression) {
      setAwardsIsActive(progression > 0.66)

      api.start({ scaleY: easeOut(progression) })
    }
  })

  return (
    <div className={styles.component}>
      <CenteredContainer width='lg'>
        <div className={styles.wrapper}>
          <div className={styles.container} {...{ ref }}>
            <Customers isActive={!awardsIsActive} />
            <Awards isActive={awardsIsActive} />
          </div>
        </div>
      </CenteredContainer>

      <Background layoutClassName={styles.backgroundLayout} {...{ scaleY }} />
    </div>
  )
}

function Background({ scaleY, layoutClassName }) {
  return (
    <span className={cx(styles.componentBackground, layoutClassName)}>
      <animated.span style={{ scaleY }} className={styles.yellowBackground} />
    </span>
  )
}

function Customers({ isActive }) {
  return (
    <div className={styles.componentCustomers}>
      <Title title='Een paar van onze klanten' {...{ isActive }} />

      <LogoList items={clients} {...{ isActive }} />
    </div>
  )
}

function Awards({ isActive }) {
  return (
    <div className={styles.componentAwards}>
      <Title title='Onze awards' {...{ isActive }} />

      <LogoList items={awards} {...{ isActive }} />
    </div>
  )
}

function Title({ title, isActive }) {
  const style = useSpring({
    delay: isActive ? 300 : 0,
    clipPath: `inset(${isActive ? 0 : 100}% 0% 0% 0%`,
    config: { tension: 300, friction: 45, precision: 0.1 }
  })

  return (
    <animated.div {...{ style }}>
      <HeadingLg h={3}>{title}</HeadingLg>
    </animated.div>
  )
}

function LogoList({ items, isActive }) {
  return (
    <ul className={styles.componentLogoList}>
      {items.slice(0, 10).map(({ name, logo }, i) => (
        <Logo key={i} index={i} {...{ name, logo, isActive }} />
      ))}
    </ul>
  )
}

function Logo({ index, name, logo, isActive  }) {
  const style = useSpring({
    delay: index * 25,
    scale: isActive ? 1 : 0,
    opacity: isActive ? 1 : 0,
    config: { tension: 400, friction: 35 }
  })

  return (
    <animated.li {...{ style }}>
      <img alt={name} src={logo} />
    </animated.li>
  )
}

export function easeOut(x) {
  return Math.sin((x * Math.PI) / 2)
}
