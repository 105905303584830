import adcn from '/components/home/assets/award-adcn.svg'
import dia from '/components/home/assets/award-dia.svg'
import san from '/components/home/assets/award-san.svg'
import epica from '/components/home/assets/award-epica-awards.svg'
import eurobest from '/components/home/assets/award-eurobest.svg'
import lovie from '/components/home/assets/award-lovie-awards.svg'
import pixel from '/components/home/assets/award-pixel-awards.svg'
import sjp from '/components/home/assets/award-sjp-magneet.svg'
import spin from '/components/home/assets/award-spin-awards.svg'
import webby from '/components/home/assets/award-webby-awards.svg'

export const awards = [
  { name: 'ADCN', logo: adcn },
  { name: 'Dutch Interactive Awards', logo: dia },
  { name: 'SAN', logo: san },
  { name: 'Epica Awards', logo: epica },
  { name: 'Eurobest', logo: eurobest },
  { name: 'Lovie Awards', logo: lovie },
  { name: 'Pixel Awards', logo: pixel },
  { name: 'SJP Magneet', logo: sjp },
  { name: 'Spin Awards', logo: spin },
  { name: 'The Webby Awards', logo: webby },
]
